export const environment = {
  production: true,
  api_url: 'https://lawhatyapistaging.kortobaa.net/',
  name: 'stag',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=app.tamayoz.com&pli=1',
  APP_STORE_URL:
    'https://apps.apple.com/us/app/%D8%AA%D9%85%D9%8A-%D8%B2-tamayoz/id6550917117',
  HUAWEI_STORE_URL: 'https://appgallery.huawei.com/app/',
  APP_SCHEMA: 'lawhatystaging',
};
